import React from 'react'
import {graphql} from "gatsby";
import PageHolder from "../components/organisms/PageHolder";
import SEO from "../components/molecules/SEO";
import Container from "../components/atoms/Container";
import styled from "styled-components";
import {GatsbyImage} from "gatsby-plugin-image";
import RichTextConverter from "../components/atoms/RichTextConverter";
import ComingSoon from "../components/atoms/ComingSoon";
import WeirdImageGrid from "../components/organisms/WeirdImageGrid";
import MarkdownConverter from "../components/atoms/MarkdownConverter";

const HeroText = styled.div`
  margin-bottom: 6rem;
  @media ( ${props => props.theme.breakpoints.lg} ) {
    display: grid;
    grid-template-columns: 4fr 2fr;
    grid-gap: 1rem;
    margin: 4rem 0;
  }

  h1 {
    ${props => props.theme.fluidType(1.2)};
    @media ( ${props => props.theme.breakpoints.lg} ) {
      ${props => props.theme.fluidType(0.8)};
    }
  }
`;

const Card = styled.div`
  position: relative;
  margin-bottom: 4rem;
  color: ${props => props.theme.colours.maroon};
  @media ( ${props => props.theme.breakpoints.lg} ) {
    margin: 0;
  }

  .gatsby-image-wrapper {
    width: 100%;
    height: auto;
    object-fit: contain;
  }
`

export default function TTM({data}) {
    const {nodes} = data.allContentfulMerch
    return (
        <>
            <ComingSoon bg="yellow" border="blue"/>
            <PageHolder bg="cream">
                <SEO title="TTM"/>
                <Container>
                    <HeroText>
                        <h1>
                            <MarkdownConverter
                                content={data.contentfulGlobalSettings.ttmPageHeading.ttmPageHeading}/>
                        </h1>
                    </HeroText>
                    <WeirdImageGrid>
                        {nodes.map((item, i) => {
                            return <Card key={i}>
                                <GatsbyImage alt={item.image.title} image={item.image.gatsbyImageData}/>
                                <RichTextConverter content={item.description}/>
                            </Card>
                        })}
                    </WeirdImageGrid>
                </Container>
            </PageHolder>
        </>
    )
}


export const pageQuery = graphql`
  {
    allContentfulMerch(sort: {fields: createdAt}) {
        nodes {
          image {
            gatsbyImageData
            title
          }
          description {
            raw
          }
        }
    }
    contentfulGlobalSettings(title: {eq: "Global Settings"}) {
      ttmPageHeading {
        ttmPageHeading
      }
    }
  }
`